.zoom-container {
  height: 100%;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;

  button {
    cursor: pointer;
  }
}
