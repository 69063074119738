@import '../../scss/colors';
@import '../../scss/mixins.scss';

.styled-input {
  @include input();

  height: 50px;
  max-height: 50px;
  font-size: 14px;
  padding: 10px 15px;
  margin: 0 0 15px 15px;
  box-sizing: border-box;
  width: calc(100% - 30px);
  border: 1px solid $light-grey;
  border-radius: 7px;
  background-color: $font-color;
  box-shadow: 0 1.5px 4px 0 rgba(0, 0, 0, 0.16),
    0 -0.5px 6px 0 rgba(0, 0, 0, 0.16);
}
