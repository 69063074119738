.confirm-payment {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  p {
    margin: 16px 0 64px;
  }

  div {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}
