@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/screen-width.scss';

form {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: $max-device-width;

  @media (min-width: $max-device-width) {
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
  }

  p {
    color: $black-color;
    font-size: 18px;
    line-height: 22px;
  }

  div {
    p {
      font-size: 15px;
      line-height: 18px;
      margin: 10px 0;
    }
  }

  .bold-text {
    font-weight: bold;
    padding-top: 32px;
    margin: 0;

    &.simple {
      line-height: 21px;
      padding-bottom: 32px;
    }

    .cancel-amount {
      height: 18px;
      width: 18px;
      margin-left: 10px;
      cursor: pointer;

      @media (max-width: $max-device-width) {
        margin-bottom: 3px;
      }
    }
  }

  .first-question {
    h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 21px 0 40px 0;

      @media (max-width: $max-device-width) {
        margin-bottom: 20px;
      }
    }

    p {
      font-weight: bold;
      margin: 0 0 5px 0;
      line-height: 21px;
    }
  }

  .description,
  .time-selector,
  .short-description {
    position: relative;
    padding: 0 16px;

    div {
      flex-wrap: nowrap;
    }

    textarea,
    input {
      font-family: inherit;
      @include input();
      @media (min-width: $max-device-width) {
        font-size: 16px;
      }
    }

    .characters-left-container {
      display: flex;
      justify-content: flex-end;

      p {
        color: $grey-text;
      }
    }

    textarea {
      background-color: $news-background;
      color: $time-picker-desktop-color;
      box-shadow: unset;
      height: 165px;

      @media (min-width: $max-device-width) {
        height: 127px;
      }
    }
  }

  .map-selector {
    .where-to-meet-text {
      font-weight: bold;
      margin: 0 0 5px 0;
      padding: 0 16px;

      @media (min-width: $max-device-width) {
        margin-bottom: 32px;
      }
    }

    .select-shops-text {
      height: 37px;
      color: $grey-text;
      margin: 0;
    }

    .coffee-to-select {
      @include coffee-list();
      margin: 7.5px 10px;

      .coffee-text-container {
        .coffee-name-container {
          .coffee-index {
            @media (max-width: $max-device-width) {
              padding-left: 0;
            }
          }
        }

        .coffee-address {
          padding: 0 15px;
          @media (min-width: $max-device-width) {
            padding: 0 5px 0 16px;
          }
        }
      }
    }

    .meeting-place-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .coffee-tab {
        display: flex;
        color: $primary-background-color;
        cursor: pointer;
        font-weight: 600;
        line-height: 22px;
        width: 50%;
        height: 50px;
        border-bottom: 3px solid $primary-background-color;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $secondary-background-color;
        }

        .coffee-place-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $primary-background-color;
          margin-right: 5px;

          img {
            width: 13.79px;
            height: 8.28px;
          }
        }

        &.unchosen {
          border-bottom: 3px solid $modal-background-color;
        }
      }

      .or {
        display: flex;
        width: 217px;
        justify-content: flex-start;
        margin: 0;

        @media (min-width: $max-device-width) {
          display: none;
        }
      }

      .zoom-tab {
        @extend .coffee-tab;
        color: $zoom-color;
        border-bottom: 3px solid $zoom-color;

        .zoom-place-icon {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }

  .undertab-container {
    background-color: $news-background;
    max-width: $max-device-width;
    padding: 25px 16px 0 16px;
    display: flex;
    flex-direction: column;

    @media (min-width: $max-device-width) {
      padding: 35px 16px 0 16px;
    }
  }

  .coffee-button {
    @include unfollow-button;
    margin: 30px 0;

    @media (max-width: $max-device-width) {
      width: 105px;
      height: 38px;
      font-size: 15px;
    }

    &.active {
      @include follow-button;
      margin: 30px 0;

      @media (max-width: $max-device-width) {
        font-size: 15px;
      }
    }
  }

  .coffee-time-container {
    max-width: $max-device-width;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .coffee-type-container {
    @extend .coffee-time-container;
  }

  .time-selector {
    margin: 0;

    div > div {
      font-size: 14px;
    }

    .search-container {
      position: relative;
      cursor: pointer;

      .search-input {
        font-size: 14px;
        height: 50px;
      }

      .clock-icon {
        height: 30px;
        width: 30px;
        color: $grey-text;
        right: 10px;
        top: 20%;
        position: absolute;
      }

      .time-pickers-container {
        @extend .time-pickers-container;
        @media (min-width: $max-device-width) {
          width: 100%;
        }
      }
    }

    .date-picker {
      display: flex;
      flex-direction: column;
      box-shadow: none;

      @media (max-width: $max-device-width) {
        width: 100%;
      }

      input {
        padding: 0;
        margin: 0;
        border: 0;
        border: none;
        box-shadow: none;
        height: 55px;
        font-size: 15px;
        font-weight: normal;
      }

      .MuiInputBase-adornedEnd {
        height: 57px !important;
        width: 274px !important;
        background-color: $news-background !important;

        @media (max-width: $max-device-width) {
          width: 100% !important;
        }
      }

      .MuiFormControl-marginNormal {
        border-bottom: none !important;
        margin: 0 !important;
      }

      .MuiIconButton-root.Mui-disabled {
        svg {
          fill: $light-grey;
        }
      }

      svg {
        stroke-width: 0;
        color: $primary-background-color;
      }

      .name {
        line-height: 21px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      @media (max-width: $max-device-width) {
        margin: 10px 0;
      }
    }

    .zoom-date-picker {
      @extend .date-picker;
      flex-direction: row;
      margin-bottom: 32px;

      input {
        background-color: $news-background;
      }

      @media (max-width: $max-device-width) {
        flex-direction: column;

        .zoom-date {
          margin-bottom: 10px;
        }
      }
    }

    .time-line-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .go-live-container {
      padding: 15px 0 15px 0;

      @media (min-width: $max-device-width) {
        padding: 15px 0 30px 0;
      }
    }

    .find-and-select-text {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 22px;
      width: auto;
      border-radius: 7px;
      border: 2px solid #fa6400;
      box-sizing: border-box;
      color: #fa6400;
      padding: 5px 0;
      margin: 32px 0 0 0;

      @media (min-width: $max-device-width) {
        width: 277px;
        height: 50px;
        border-radius: 42px;
        border: 3px solid #fa6400;
        font-weight: 700;
        font-size: 14px;
        padding: 0;
      }

      .place-icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  .duration {
    .slider {
      width: 100%;
      appearance: none;
      height: 0.15rem;
      outline: none;
      border-radius: 0.25rem;
      background-color: $slider-color;

      @media (min-width: $max-device-width) {
        height: 3px;
        background-color: $primary-background-color;
      }

      &::-webkit-slider-thumb {
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: $primary-background-color;
        border: 0.3rem solid $border-color;
        border-radius: 1rem;
        cursor: pointer;

        @media (min-width: $max-device-width) {
          background-color: $border-color;
          border: 0.3rem solid $primary-background-color;
        }
      }
    }

    .time-value {
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      padding-top: 10px;
      margin: 0;

      @media (min-width: $max-device-width) {
        font-size: 16px;
        padding-top: 20px;
      }
    }
  }

  @include button();

  .create-button-container {
    display: flex;
    justify-content: center;
    padding: 0 16px;
    position: sticky;
    bottom: 0;
    background-color: $font-color;

    .active {
      background-color: $primary-background-color;
      color: $font-color;
      border: 1px solid $primary-background-color;
      transition: background-color 0.2s linear, border 0.2s linear;
      &:hover {
        background-color: $primary-background-color-hover;
        border: 1px solid $primary-background-color-hover;
      }
    }

    @media (min-width: $max-device-width) {
      .active {
        border: 3px solid $primary-background-color;
      }
    }

    button {
      font-family: inherit;
      font-size: 16px;
      border: 3px solid $light-grey;
      color: $light-grey;

      @media (max-width: $max-device-width) {
        border: 1px solid $light-grey;
      }
    }
  }

  button {
    margin: 30px 0;

    @media (min-width: $max-device-width) {
      height: 50px;
      max-width: 200px;
      font-size: 14px;
    }
  }

  .new-coffee-title {
    font-weight: bold;
    font-size: 20px;
    padding: 25px 16px 0 16px;

    @media (max-width: $max-device-width) {
      display: none;
    }
  }

  .plan-your-coffee {
    margin-bottom: 20px;
    font-weight: bold;
  }
}

form::-webkit-scrollbar {
  width: 0;
}

.datepicker.ios {
  font-family: inherit;
  font-size: 16px;

  .datepicker-scroll li:nth-last-child(5) {
    color: $primary-background-color;
  }
}

.error {
  border: 1px solid $red !important;
}

.validation {
  color: $red;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 16px 0 16px;

  p,
  button {
    margin: 0;
    padding: 0;
  }

  button {
    color: $red;
    border: solid 3px $red;
  }
}

.get-paid {
  @include follow-button;
  margin: 0;
  @media (max-width: $max-device-width) {
    font-size: 15px;
  }
}

.switcher {
  margin: 0;
}

.swith-container {
  padding: 32px 0;

  &.coffee {
    padding-bottom: 0;
  }
}

.stripe-icon {
  height: 34px;
  width: 72px;
  color: $stripe;
}

.stripe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  background-color: $modal-background-color;
  border-color: $modal-background-color;
  font-family: inherit;
  margin: 10px 0;
  cursor: pointer;
}

.payment-type-container {
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  gap: 5px;

  div {
    display: flex;
    align-items: center;
  }
}

.choose-talk {
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
}

.get-paid-tooltip {
  background-color: #f5f8fa !important;
  color: #000 !important;
  border-radius: 10px;
  box-shadow: 0 -0.5px 6px 0 rgb(0 0 0 / 16%), 0 1.5px 4px 0 rgb(0 0 0 / 16%);

  div {
    padding: 4px;

    p {
      line-height: 14px;
      font-size: 14px;
    }

    p + p {
      margin-top: 8px;
    }
  }
}
