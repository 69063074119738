@import "../../scss/colors";
@import "../../scss/mixins";

header {
  background-color: $primary-background-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $font-color;
  height: 55px;
  min-height: 55px;
  box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
    0 1.5px 4px 0 rgba(0, 0, 0, 0.16);
  position: fixed;
  width: 100%;

  .back-button-with-icon {
    @include back-button-with-icon;
  }

  h1 {
    font-size: 27px;
    font-weight: lighter;
    margin: 0 15px;
  }

  .edit-my-availability {
    cursor: pointer;
    margin: 0 15px;
    height: 15px;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    display: flex;
    align-items: center;
  }
}
