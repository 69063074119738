@import "../../scss/colors";
@import "../../scss/mixins";

.news-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 12.5px 0;
  width: 100%;
  text-decoration: none;
  text-decoration: none;

  @media (min-width: $max-device-width) {
    flex-direction: row-reverse;
    margin: 0;
    border-bottom: 1px solid $line-color;
  }

  .news-info {
    width: 100%;

    .news-source {
      font-size: 12px;
      color: $grey-text;
      font-weight: bold;
    }

    .news-title {
      font-size: 15px;
      font-weight: 600;
      width: 95%;
      margin-left: 0;
      color: $black-color;
    }

    .news-published {
      font-size: 12px;
      color: $grey-text;
    }

    @media (min-width: $max-device-width) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-width: 180px;
      padding: 5px 0;

      p {
        margin: 0;
      }
    }
  }

  .news-image {
    flex-shrink: 0;
    height: 69px;
    width: 84px;
    object-fit: cover;
    color: $black-color;

    @media (min-width: $max-device-width) {
      height: 77px;
      width: 77px;
      border-radius: 6px;
      margin: 16px 20px 15px 20px;
    }
  }
}

@media (min-width: $max-device-width) {
  .news-content:last-child {
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .news-content:hover {
    background-color: $middle-news;
  }
}
