@import '../../scss/colors.scss';
@import '../../scss/mixins';
@import '../../scss/screen-width.scss';

.modal-window {
  top: 0;
  right: 0;
  z-index: 100;
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.13);

  .modal-window-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    max-height: 400px;
    height: 100%;
    max-width: 600px;
    background-color: $font-color;
    border-radius: 10px;
    box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
      0 1.5px 4px 0 rgba(0, 0, 0, 0.16);

    @media (max-width: $max-device-width) {
      max-width: 90%;
    }

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 80%;
      font-size: 20px;
      text-align: -webkit-center;
    }

    .accept {
      position: relative;
      background-color: $font-color;
      border: 2px solid red;
      height: 81px;
      width: 81px;
      border-radius: 50%;
      margin: 5% 0;
      color: red;
      font-size: 14px;
      flex-shrink: 0;
      cursor: pointer;
    }

    // .accept:hover {
    //   color: red;
    //   background-color: rgba(250, 0, 0, 0.5);
    // }

    .decline {
      position: relative;
      margin: 5% 0;
      font-size: 14px;
      cursor: pointer;
      border: none;
    }
  }
}
