@import '../../../scss/colors.scss';

.setting-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 19px 11px 19px;

  .title {
    font-weight: bold;
  }

  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-background-color;
    font-size: 14px;
    margin: 11px 0;
    cursor: pointer;
    font-family: inherit;
    border-radius: 12px;
    transition: background-color 0.2s linear;
    padding: 0 8px;

    &:hover {
      background-color: $secondary-background-color;
    }

    .plus-icon {
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }
  }

  .setting-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;

    button {
      cursor: pointer;
      padding: 0;
    }

    span {
      margin-right: 10px;
    }

    .edit-setting-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      width: 32px;
      height: 32px;
      background-color: $edit-setting-button-color;
      border-radius: 50%;
      color: $time-picker-desktop-color;

      .dots {
        height: 32px;
        width: 24px;
      }
    }
  }

  .settings-menu-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 2;
  }
}
