@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.grab-coffee-container {
  overflow: auto;
  height: 100%;
  width: 100%;
  max-width: calc(598px);
  position: fixed;
  top: 0;
  background-color: $font-color;
  z-index: 10;

  @media (min-width: $max-device-width) {
    position: sticky;
  }

  .stepper-container {
    width: 100%;
    max-width: $max-device-width;
    display: flex;
    justify-content: center;
    color: $font-color;
    padding: 33px 0 23px 0;

    .step {
      display: flex;
      width: 47.5%;

      .stepper-body {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 150px;
        width: 100%;
        height: 30px;
        background-color: $grey-text;
      }

      .arrow-container {
        background-color: grey;
        position: relative;

        .stepper-arrow-border-div {
          width: 3px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $font-color;
        }

        .stepper-arrow {
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-left: 10px solid $grey-text;
          border-bottom: 15px solid transparent;
          position: absolute;
          z-index: 10;
        }

        .stepper-arrow-border {
          z-index: 5;
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          position: absolute;
          border-left: 10px solid $font-color;
          left: 3px;
        }
      }

      .last-step {
        display: none;
      }

      &.active {
        .stepper-body {
          background-color: $primary-background-color;
        }

        .stepper-arrow {
          border-left: 10px solid $primary-background-color;
        }
      }
    }
  }

  .map-container {
    .where-to-meet-text {
      margin: 0 0 5px 0;
      padding: 0 15px;

      @media (min-width: $max-device-width) {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .select-shops-text {
      // color: $grey-text;
      margin: 0 0 15px 0;
      padding: 0 15px;

      @media (min-width: $max-device-width) {
        font-size: 14px;
        color: $black-color;
      }
    }
  }

  .coffee-list-container {
    margin: 0;

    // @media (min-width: $max-device-width) {
    //   margin: 435px 0 0 0;
    // }
  }

  .finish-button-container {
    display: flex;
    margin: 20px 0 75px 0;
    padding: 0 20px;
    max-width: 600px;
    @include button();

    .active {
      color: $primary-background-color;

      &:hover {
        color: $primary-background-color-hover;
        border-color: $primary-background-color-hover;
      }
    }

    @media (min-width: $max-device-width) {
      justify-content: space-between;
    }

    .cancel-button {
      cursor: pointer;
      width: 35%;
      margin: 0 11px 0 0;

      @media (min-width: $max-device-width) {
        flex-shrink: 0;
        width: 119px;
      }
    }

    .done-button {
      cursor: pointer;
      max-width: 63%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: $max-device-width) {
        flex-shrink: 0;
      }
    }

    // .done-button:hover {
    //   color: $primary-background-color;
    //   background-color: rgba(250, 100, 0, 0.5);
    //   border: 3px solid $primary-background-color;
    // }

    // .cancel-button:hover {
    //   background-color: rgba(213, 213, 213, 0.5);
    //   border: 3px solid $light-grey;
    // }

    .back-button {
      @extend .cancel-button;
      cursor: pointer;
      width: 35%;

      &:hover {
        background-color: $grey-text;
      }

      @media (min-width: $max-device-width) {
        width: 119px;
      }
    }
  }

  .invite-button-container {
    @extend .finish-button-container;
    margin-top: 25px;
    margin-bottom: 75px;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $max-device-width) {
      justify-content: space-between;
    }
  }
}

.grab-coffee-container::-webkit-scrollbar {
  width: 0;
}

.grab-coffee-meeting-details {
  @include meeting-details;
}

.grab-coffee-meeting-details-titles {
  width: 100%;

  @include meeting-details-titles;

  .coffee-details-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
    margin: 12.5px 0;

    &.zoom {
      color: $zoom-color;
    }

    @media (min-width: $max-device-width) {
      flex-direction: column;
    }

    .title {
      flex-shrink: 0;
      width: 100px;
    }

    .bold-details-text {
      font-size: 14px;
      font-weight: bold;
      color: $black-color;

      @media (min-width: $max-device-width) {
        font-weight: normal;
        // color: $grey-text;
      }
    }

    .offer-to-pay-text {
      color: $black-color;
      font-size: 14px;

      @media (min-width: $max-device-width) {
        // color: $grey-text;
      }
    }

    .zoom-link {
      color: $zoom-color;
      word-break: break-all;
      margin-right: 16px;
    }

    .next-steps-text {
      font-size: 14px;
    }
  }
}

.grab-coffee-meeting-details-time {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include meeting-details-data;

  .text-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .add-time {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    @include button();

    .active {
      color: $primary-background-color;
    }

    .not-active {
      color: $light-grey;
      border: 3px solid $light-grey;
    }

    // .active:hover {
    //   color: $primary-background-color;
    //   background-color: rgba(250, 100, 0, 0.5);
    // }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 50px;
    }

    .five-minutes {
      color: $black-color;
      margin: 0 10px;

      @media (min-width: $max-device-width) {
        // color: $grey-text;
      }
    }
  }
}

.grab-coffee-meeting-details-coffee-shop {
  @include meeting-details-data;
}

.offer-to-pay-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: $max-device-width) {
    flex-direction: row;
  }
}

.grab-offer-to-pay {
  margin: 25px 0 0 0;
  color: $light-grey;
  font-size: 14px;
  flex-shrink: 0;
  width: 100px;

  @media (min-width: $max-device-width) {
    // color: $black-color;
    margin: 0;
  }
}

.toggle-button {
  @include toggle-button;
  cursor: pointer;
}

.treat-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  .grab-offer-to-pay-text {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: $black-color;

    @media (min-width: $max-device-width) {
      font-weight: normal;
      color: $light-grey;
    }
  }
}
