@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.list-container {
  width: 495px;
  height: 772px;
  background-color: $font-color;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: $max-device-width) {
    width: 100%;
    height: 100%;
    max-width: 80%;
    max-height: 70%;
  }

  .list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 24px;
    }

    button {
      cursor: pointer;
      padding: 0;
    }
  }

  .followers {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 720px;

    @media (max-width: $max-device-width) {
      height: 95%;
    }
  }
}

.sub-img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 20px;
}

.follower-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  & > div {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
    }
  }

  .unfollow-button {
    @include unfollow-button;
    margin: 0;
  }
}
