@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.home-page-container {
  height: 100%;
  width: 100%;
  max-width: $max-device-width;
  display: flex;
  flex-direction: column;

  @media (min-width: $max-device-width) {
    min-height: 100vh;
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
  }

  .search-input-container {
    @include search-input-container;
    margin: 0;
    border-bottom: 1px solid $line-color;

    input {
      font-family: inherit;
    }

    @media (min-width: $max-device-width) {
      position: sticky;
      top: 0;
      background-color: $font-color;
      z-index: 1;
      height: initial;
    }
  }

  .coffee-cards-container {
    @include coffee-cards-container;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;

    @media (min-width: $max-device-width) {
      overflow-y: unset;
    }

    .coffee-card-container {
      margin: 7px 0;
    }
  }
}
