@import '../../scss/mixins.scss';

.past-coffee-card {
  @include past-coffee-card;
}

.past-coffee-card:hover {
  background-color: $secondary-background-color;
}

.avatars-container{
  width: 120px;


  @media (max-width: $max-device-width) {
    width: 85px;
  }
}