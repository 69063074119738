@import "./colors";
@import "./screen-width.scss";

@mixin button {
  button {
    cursor: pointer;
    height: 38px;
    background-color: $font-color;
    color: $black-color;
    border: 1px solid $black-color;
    border-radius: 7px;
    flex-shrink: 0;
    width: 100%;
    font-size: 16px;

    @media (min-width: $max-device-width) {
      border-radius: 42px;
      border: 3px solid $black-color;
      font-weight: bold;
      height: 50px;
    }
  }

  .active {
    @media (min-width: $max-device-width) {
      color: $primary-background-color;
    }

    border: 2px solid $primary-background-color;

    @media (min-width: $max-device-width) {
      border: 3px solid $primary-background-color;
    }
  }

  // .active:hover {
  //   color: $font-color;
  //   background-color: $primary-background-color;
  // }
}

@mixin coffee-list {
  display: flex;
  margin: 15px 0 0 0;

  @media (min-width: $max-device-width) {
    border-radius: 10px;
    height: 82px;
    border: 1px solid $light-grey;
    box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
    0 1.5px 4px 0 rgba(0, 0, 0, 0.16);
  }

  .coffee-text-container {
    width: 75%;

    p {
      margin: 0;
    }

    .coffee-name-container {
      display: flex;

      .coffee-index {
        width: 10px;
        padding: 0 5px 0 16px;
        color: $primary-background-color;
      }

      .coffee-name {
        padding: 0;
        font-weight: 600;

        @media (min-width: $max-device-width) {
          padding: 0 0 0 7px;
        }
      }
    }

    .coffee-address {
      padding: 5px 30px;
      width: 100%;

      @media (min-width: $max-device-width) {
        padding: 5px 0 0 38px;
      }
    }

    @media (min-width: $max-device-width) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

@mixin input {
  font-size: 24px;
  padding: 10px 16px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $light-grey;
  border-radius: 7px;
  background-color: $font-color;
  -webkit-appearance: none;
  box-shadow: 0 1.5px 4px 0 rgba(0, 0, 0, 0.16),
  0 -0.5px 6px 0 rgba(0, 0, 0, 0.16);
  resize: none;

  @media (min-width: $max-device-width) {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    font-size: 16px;
    color: $input-color;
  }
}

@mixin coffee-card {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin: 5px 0;
  border-radius: 7px;
  background-color: $font-color;
  box-shadow: 0 1.5px 4px 0 $primary-shadow-color,
  0 -0.5px 6px 0 $primary-shadow-color;
  cursor: pointer;
  flex-shrink: 0;

  @media (min-width: $max-device-width) {
    min-height: 115px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
  }

  .coffee-card-user-avatar {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 50%;

    @media (min-width: $max-device-width) {
      margin-top: 34px;
      margin-left: 24px;
      height: 46px;
      align-items: center;
    }

    .coffee-card-user-img {
      object-fit: cover;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 2px solid $font-color;

      &.live {
        border: solid 3px $red;
      }

      @media (min-width: $max-device-width) {
        height: 46px;
        width: 46px;
      }
    }

    .coffee-card-user-name {
      white-space: nowrap;
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-right: 10px;
    }
  }

  .coffee-card-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 0 15px 16px;

    @media (min-width: $max-device-width) {
      width: 83%;
      margin: 0 20px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .data-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding-right: 10px;

      @media (min-width: $max-device-width) {
        width: 47%;

        &.time {
          width: 53%;
        }
      }
    }

    .coffee-card-title {
      margin: 0;
      font-size: 24px;
      word-wrap: break-word;
      width: 100%;

      &.closed {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      @media (min-width: $max-device-width) {
        font-size: 20px;
      }
    }

    .coffee-card-tag {
      margin: 0 0 5px 0;
      padding: 0 15px;
      border-radius: 11.5px;
      background-color: $tag-background-color;
      height: 23px;
      font-size: 11px;
      line-height: 25px;
      text-align: center;
    }

    .coffee-card-conversation-time {
      margin: 0;
      color: $light-grey;
      font-size: 11px;

      @media (min-width: $max-device-width) {
        font-size: 14px;
        padding-bottom: 5px;
      }
    }

    .coffee-card-meet-info {
      display: flex;
      align-items: center;
      margin: 3px 0;
      font-size: 11px;

      &.live {
        color: $red;
      }

      @media (min-width: $max-device-width) {
        font-size: 14px;

        @media (max-width: 1150px) {
          font-size: 12px;
        }
      }

      .coffee-card-icon {
        margin-right: 8px;
        height: 12px;
        width: 12px;
        color: $primary-background-color;
        flex-shrink: 0;
        stroke-width: 0;
      }

      .live-time {
        color: $red;
        font-weight: 600;
      }
    }

    .zoom-link {
      margin-right: 16px;

      a {
        color: $zoom-color;
        word-break: break-all;
        font-size: 14px;

        @media (max-width: $max-device-width) {
          font-size: 11px;
        }
      }
    }
  }
}

@mixin opened-coffee-card {
  @include coffee-card;
  @media (min-width: $max-device-width) {
    .coffee-card-user-avatar {
      margin: 34px 0 0 24px;

      div {
        display: inherit;
      }

      .coffee-card-user-name {
        margin-left: 24px;
        font-size: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 24px;
      }


    }

    .coffee-card-data {
      margin: 0 0 15px 95px;
      flex-direction: column;
      align-items: flex-start;

      .coffee-card-participants {
        background: $setting-cancel-button-color;
        border-radius: 46px;
        height: 32px;
        display: flex;
        align-items: center;
        color: $black-color;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 0 8px;
        cursor: pointer;
        transition: background-color 0.2s linear;

        &:hover{
          background: $grey-text;
        }

        .coffee-card-participants-amount {
          margin-left: 4px;
          color: $time-picker-desktop-color;
        }
      }

      .data-container {
        height: 100%;
        width: 80%;

        .coffee-card-tag {
          font-size: 14px;
        }

        .coffee-card-title {
          margin: 30px 0 20px 0;
        }

        .coffee-card-conversation-time {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }

  flex-direction: column;

  .coffee-card-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 39px;
    width: 97%;
    margin-top: 15px;
    color: $primary-background-color;
    outline: none;
    border-radius: 7px;
    border: 1px solid $primary-background-color;
    background: $font-color;
    flex-shrink: 0;
    font-size: 16px;
    transition: background-color 0.2s linear, border 0.2s linear, color 0.2s linear;

    &:hover {
      color: $primary-background-color-hover;
      border: 3px solid $primary-background-color-hover;
      background: $secondary-background-color;
    }

    span {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      @media (min-width: $max-device-width) {
        margin: 0 5px;
      }
    }

    .coffee-card-cup {
      margin-right: 15px;
      width: 23px;
      height: 14px;

      @media (min-width: $max-device-width) {
        width: 23px;
        height: 14px;
        margin: 0 5px;
      }
    }

    .zoom-card-icon {
      margin-right: 15px;

      @media (max-width: $max-device-width) {
        width: 24px;
        height: 24px;
      }
    }

    @media (min-width: $max-device-width) {
      height: 50px;
      margin-bottom: 15px;
      border-radius: 42px;
      border: 3px solid $primary-background-color;
      max-width: 83%;
      min-width: 245px;
      width: auto;
      font-weight: bold;
    }
  }
}

@mixin coffee-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  .coffee-card-container {
    margin: 7px 0;
  }
}

@mixin meeting-details {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

@mixin meeting-details-titles {
  @include meeting-details;
  flex-direction: column;
  font-size: 14px;
  color: $light-grey;
  min-width: 76px;
}

@mixin meeting-details-data {
  @include meeting-details;
  flex-direction: column;

  .bold-details-text {
    font-size: 14px;
    font-weight: bold;
    color: $black-color;
  }

  .details-text {
    font-size: 14px;
    color: $black-color;

    @media (min-width: $max-device-width) {
      // color: $grey-text;
    }
  }
}

@mixin past-coffee-card {
  @include coffee-card;
  cursor: default;

  @media (min-width: $max-device-width) {
    padding: 20px 0;
  }

  .coffee-card-user-avatar {
    cursor: pointer;
    @media (min-width: $max-device-width) {
      margin-top: 24px;
    }
  }

  .coffee-card-interlocutor-avatar {
    height: 36px;
    border-radius: 50%;
    position: relative;
    top: -21px;
    left: 11px;
    cursor: pointer;

    @media (min-width: $max-device-width) {
      top: -24px;
      left: 24px;
    }

    .coffee-card-user-img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 2px solid transparent;
      object-fit: cover;

      @media (min-width: $max-device-width) {
        height: 46px;
        width: 46px;
      }
    }
  }

  .coffee-card-data {
    width: 80%;
    margin-left: -20px;

    @media (min-width: $max-device-width) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .coffee-card-meet-info {
      .coffee-card-icon {
        color: $light-grey;
      }
    }
  }
}

@mixin toggle-button {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 33px;
  right: 0;

  .slider-off {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 18px;
    background-color: $light-grey;
    transition: background-color 0.5s;

    .slider-button {
      left: 2px;
      top: 1px;
      border-radius: 50%;
      position: absolute;
      height: 18px;
      width: 18px;
      background-color: $secondary-background-color;
      transition: left 0.5s;
    }
  }

  .slider-on {
    @extend .slider-off;
    background-color: $toggle;
    transition: background-color 0.5s;

    .slider-button {
      left: 14px;
      top: 1px;
      transition: left 0.5s;
    }
  }
}

@mixin meeting-user-verifications {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 10px 0 17.5px 0;

  .verification-text {
    font-size: 14px;
    font-style: bold;
    margin-right: 10px;
  }
}

@mixin for-bottom-space($value) {
  @media (max-height: 700px) {
    padding-bottom: $value;
  }
}

@mixin search-input-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 0 10px 0;
  display: inline-block;

  .search-icon {
    position: absolute;
    top: 28px;
    left: 8%;
    height: 20px;
    width: 20px;
    color: $grey-text;

    @media (min-width: $max-device-width) {
      top: 29px;
    }
  }

  .search-input {
    @include input();
    height: 50px;
    font-size: 16px;
    padding-left: 10%;
    width: 95%;
    margin: 15px;

    @media (max-width: $max-device-width) {
      width: calc(100vw - 8%);
    }
  }

  @media (min-width: $max-device-width) {
    height: 75px;
    font-size: 16px;
  }
}

@mixin bottom-links {
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 90%;
  font-size: 12px;
  color: $time-picker-desktop-color;
  margin: 14% 0;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    font-size: 8px;
  }

  @media (max-height: 830px) {
    margin: 5% 0;
  }

  a {
    color: $time-picker-desktop-color;
    flex-shrink: 0;
  }

  .dot {
    display: flex;
    justify-content: center;
    width: 30px;
    flex-shrink: 0;
  }
}

@mixin back-button-with-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .back-navigation {
    cursor: pointer;
    position: absolute;
    left: 0;
    font-size: 30px;
  }

  .close-navigation {
    @extend .back-navigation;
    left: 5px;
  }
}

@mixin new-coffee-talk-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 190px;
  height: 50px;
  background: $primary-background-color;
  border-radius: 39px;
  color: $font-color;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  @media (max-width: $max-device-width) {
    width: 130px;
  }

  &.disable {
    opacity: 0.2;
  }

  .coffee-talk-text {
    margin-left: 10px;
  }

  transition: background-color linear 0.2s;

  &:hover {
    background-color: $primary-background-color-hover;
  }
}

@mixin follow-button {
  border-radius: 42px;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 3px solid $primary-background-color;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 36px;
  background-color: $primary-background-color;
  color: $font-color;
  font-weight: bold;
  transition: background-color 0.2s linear, border 0.2s linear;

  &:hover{
    border: 3px solid $primary-background-color-hover;
    background-color: $primary-background-color-hover;
  }

  @media (max-width: $max-device-width) {
    font-size: 16px;
    border-radius: 7px;
    border: 1px solid $primary-background-color;
    font-weight: normal;
    padding: 5px 10px;
  }
}

@mixin unfollow-button {
  @include follow-button;
  background-color: $setting-cancel-button-color;
  border: 3px solid $setting-cancel-button-color;
  color: $black-color;

  &:hover{
    background-color: $grey-text;
    border: 3px solid $grey-text;
  }

  @media (max-width: $max-device-width) {
    border: 1px solid $setting-cancel-button-color;
  }
}
