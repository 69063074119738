@import "../../scss/mixins.scss";
@import "../../scss/colors.scss";

.coffee-card {
  @include coffee-card;
}

.coffee-card:hover {
  background-color: $secondary-background-color;
}

.almost-over-time {
  color: red;
}

.opened-coffee-card {
  @include opened-coffee-card;
}

.opened-coffee-card {
  .zoom-card-button {
    @extend .coffee-card-button;
    // width: 148px;
    min-width: 148px;
    border: 3px solid $zoom-color;
    box-sizing: border-box;
    color: $zoom-color;
    background-color: $font-color;
    margin-top: 10px;

    &:hover {
      color: $blue !important;
      border: 3px solid $blue !important;
      background: $secondary-background-color !important;
    }
  }
}

.opened-coffee-card:hover {
  .coffee-card-button {
    background-color: $secondary-background-color;

    &:hover {
      color: $primary-background-color-hover;
      border: 3px solid $primary-background-color-hover;
      background: $secondary-background-color;
    }
  }

  // .coffee-card-button:hover {
  //   background-color: rgba(250, 100, 0, 0.5);
  // }
}

.follow-button {
  @include follow-button;
  margin: 0;

  @media (max-width: $max-device-width) {
    font-size: 16px;
    border-radius: 7px;
    border: 1px solid $primary-background-color;
    font-weight: normal;
  }
  transition: background-color linear 0.2s, border linear 0.2s;

  &:hover{
    background-color: $primary-background-color-hover;
    border: 3px solid $primary-background-color-hover;
  }
}

.unfollow-button {
  @include unfollow-button;
  margin: 0;

  @media (max-width: $max-device-width) {
    font-size: 16px;
    border-radius: 7px;
    border: 1px solid $setting-cancel-button-color;
    font-weight: normal;
  }
}

.coffee-details-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin: 12.5px 0;
  font-size: 14px;

  @media (min-width: $max-device-width) {
    flex-direction: column;
  }

  .title {
    flex-shrink: 0;
    width: 100px;
    color: $light-grey;
  }

  .zoom-link {
    color: $zoom-color;
    word-break: break-all;
    margin-right: 16px;
  }
}

.participants-modal-background {
  z-index: 20;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.13);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .participants-modal {
    width: calc(min(600px, 100vh));

    height: auto;
    background-color: $font-color;
    border-radius: 10px;
    padding: 20px;

    .participants-modal-header {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;

      .coffee-card-participants-amount {
        font-weight: 400;
        font-size: 20px;
        line-height: 21px;
        color: $time-picker-desktop-color;
      }

      svg {
        cursor: pointer;

        &:hover {
          color: $primary-background-color;
        }
      }
    }

    .participants-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(min(600px, 100vh));
      overflow-y: auto;

      .participants-modal-body-item + .participants-modal-body-item {
        border-top: 1px solid $line-color;;
      }

      .participants-modal-body-header {
        display: flex;
        width: 100%;

        & > div {
          display: flex;
          align-items: center;

          svg {
            cursor: pointer;
            margin-left: 4px;
          }

          .rotated {
            transform: rotate(180deg);
          }
        }

        .name, .amount, .date, .number {
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          color: $time-picker-desktop-color;
        }

        .number {
          flex: 1;
        }

        .name {
          flex: 11;
        }

        .date {
          flex: 4;
        }

        .amount {
          flex: 4;
        }


      }

      .participants-modal-body-item {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 10px 0;

        .number {
          flex: 1;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
        }

        .name {
          flex: 11;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          max-width: 55%;
          overflow: hidden;

          .participant-user-img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin-right: 4px;
          }
        }

        .date {
          flex: 4;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: $time-picker-desktop-color;
          max-width: 20%;
          overflow: hidden;
        }

        .amount {
          flex: 4;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          max-width: 20%;
          overflow: hidden;
        }
      }
    }
  }
}
