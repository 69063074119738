@import "../../scss/colors.scss";
@import "../../scss/screen-width.scss";

.pop-up-notification-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 101;
  bottom: 10%;

  @media (min-width: $max-device-width) {
    bottom: 5%;
    right: 5%;
    justify-content: flex-end;
  }

  .notification {
    text-align: center;
    border: 2px solid $primary-background-color;
    background-color: $primary-background-color;
    color: $font-color;
    font-size: 18px;
    border-radius: 7px;
    padding: 10px;
    width: 300px;
    display: flex;
    flex-direction: row;

    div {
      vertical-align: middle;
    }
  }

  .close-notification {
    cursor: pointer;
    margin: 0 5px;
    padding: 0;
  }
}
