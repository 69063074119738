@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';
@import '../../scss/screen-width.scss';
@import '../../scss/colors.scss';

.rate-meeting-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: $max-device-width;
  padding-top: 25px;
  height: 100vh;

  @media (min-width: $max-device-width) {
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
  }

  @include button();

  .rate-meeting-title {
    font-size: 18px;
    margin: 25px 0;
    text-align: center;
  }

  .rate-meeting-question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12.5px 0;
    width: 95%;

    .rate-meeting-question {
      width: 90%;
      font-size: 14px;
    }
  }

  button {
    width: 95%;
    margin: 7.5px auto;
    font-size: 14px;
    color: $primary-background-color;
  }
}

.toggle-button {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 33px;
  right: 0;

  .slider-off {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 18px;
    background-color: $light-grey;
    transition: background-color 0.5s;

    .slider-button {
      left: 2px;
      top: 1px;
      border-radius: 50%;
      position: absolute;
      height: 18px;
      width: 18px;
      background-color: $secondary-background-color;
      transition: left 0.5s;
    }
  }

  .slider-on {
    @extend .slider-off;
    background-color: $toggle;
    transition: background-color 0.5s;

    .slider-button {
      left: 14px;
      top: 1px;
      transition: left 0.5s;
    }
  }
}
