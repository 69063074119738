@import "../../scss/colors.scss";
@import "../../scss/mixins.scss";

.invitation-details-container {
  font-size: 16px;
  padding: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-device-width;
  height: 100vh;

  @media (min-width: $max-device-width) {
    overflow-y: scroll;
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
  }

  .invitation-details {
    font-size: 18px;
    margin-bottom: 13px;
  }

  .coffee-card {
    @include coffee-card;
    width: 100%;
  }

  .opened-coffee-card {
    @include opened-coffee-card;
    width: 100%;
  }

  .invitation-user-verifications {
    @include meeting-user-verifications;
    margin: 10px 0 25px 0;
  }
}

.invitation-details-container::-webkit-scrollbar {
  width: 0;
}

.invitation {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex-shrink: 0;

  .invitation-text {
    margin: 10px 0 25px 0;
    word-wrap: break-word;
  }

  .perspective-text {
    margin-top: 17.5px;
    color: $grey-text;

    @media (min-width: $max-device-width) {
      color: $black-color;
    }
  }
}

.invitation-meeting-details {
  @include meeting-details;
  flex-shrink: 0;
}

.invitation-meeting-details-titles {
  @include meeting-details-titles;
}

.invitation-meeting-details-data {
  @include meeting-details-data;

  .offer-to-pay-text {
    font-size: 14px;
  }
}

.invitation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  flex-shrink: 0;

  @include button();

  .active {
    color: $primary-background-color;
  }

  button {
    font-weight: bold;
    margin: 7.5px auto;
  }

  // button:hover {
  //   color: $font-color;
  //   background-color: $light-grey;
  // }

  @media (min-width: $max-device-width) {
    justify-content: space-between;
    flex-direction: row;
    margin: 70px 0 50px 0;

    button {
      max-width: 146px;
      margin: 0;
    }

    .active {
      margin: 0;
      max-width: 180px;
    }

    // .active:hover {
    //   color: $font-color;
    //   background-color: $primary-background-color;
    // }
  }
}
