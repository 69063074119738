@import "../../scss/colors";
@import "../../scss/screen-width.scss";

.support-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: $font-color;
  z-index: 1;

  @media (max-width: $max-device-width) {
    overflow-y: hidden;
  }

  .support {
    margin: 20px;
    padding: 20px;
    background-color: $news-background;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    @media (max-width: $max-device-width) {
      margin: 30px 0 0 0;
      height: 100%;
    }

    p {
      margin: 0;
    }
  }
}

.app-title-container {
  .app-title {
    color: $primary-background-color;
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
  }
}

a {
  color: $primary-background-color;
}
