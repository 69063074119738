@import "../../scss/colors";
@import "../../scss/mixins";

.custom-hr {
  border-top: 3px solid $light-grey;

  @media (max-width: $max-device-width) {
    border-top: 1px solid $light-grey;
  }
  margin: 12.5px 16px 0 0;
  width: 95%;
}
