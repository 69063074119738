@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.modal {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  max-width: $max-device-width;

  @media (min-width: $max-device-width) {
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
    min-height: 100vh;
    overflow-y: unset;
  }

  p {
    color: $black-color;
    font-size: 18px;
    line-height: 22px;
  }

  div {
    p {
      padding: 0 16px;
      font-size: 14px;
      line-height: 18px;
      margin: 10px 0;
    }

    input {
      // @media (min-width: $max-device-width) {
      //   position: sticky;
      //   top: 86px;
      //   z-index: 1;
      //   background-color: $font-color;

      //   .styled-input {
      //     padding: 0 0 15px 15px;
      //     margin: 0;
      //   }
      // }
    }
  }

  .intro-text + div {
    // @media (min-width: $max-device-width) {
    //   position: sticky;
    //   top: 86px;
    //   background-color: $font-color;
    //   z-index: 1;
    //   background-color: $font-color;
    // }
  }

  .intro-text {
    padding-top: 25px;

    @media (min-width: $max-device-width) {
      position: sticky;
      top: 0;
      background-color: $font-color;
      z-index: 1;
    }

    .where-to-meet-text {
      margin: 0 0 5px 0;

      @media (min-width: $max-device-width) {
        font-weight: bold;
      }
    }

    .select-shops-text {
      height: 37px;
      color: $grey-text;
      margin: 0;
    }
  }

  .finish-button-container {
    display: flex;
    margin: 20px 16px 0 16px;
    padding-bottom: 20px;
    @include button();

    .cancel-button {
      width: 35%;
      margin: 0 11px 0 0;
    }

    .done-button {
      width: 62%;

      @media (min-width: $max-device-width) {
        width: 64%;
      }
    }

    .active {
      color: $primary-background-color;
    }

    @media (min-width: $max-device-width) {
      .active {
        color: $primary-background-color;
      }
    }

    // .done-button:hover {
    //   color: $primary-background-color;
    //   background-color: rgba(250, 100, 0, 0.5);
    //   border: 3px solid $primary-background-color;
    // }

    // .cancel-button:hover {
    //   background-color: rgba(213, 213, 213, 0.5);
    //   border: 3px solid $light-grey;
    // }
  }

  .cafes-list {
    @media (min-width: $max-device-width) {
      .coffee-to-select {
        margin: 15px 10px 0 10px;
      }
    }
  }
}
