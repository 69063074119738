@import "../../scss/screen-width.scss";
@import "../../scss/colors.scss";

.privacy-policy {
  padding: 20px;
  overflow-y: auto;

  @media (min-width: $max-device-width) {
    padding: 20px;
    box-sizing: border-box;
    //max-width: $max-device-width;
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
  }

  section {
    margin: 30px 0;
  }
}
