@import "./scss/colors.scss";
@import "./scss/screen-width.scss";

@font-face {
  src: url("./shared/fonts/GT-Walsheim-Regular.otf") format("opentype");
  font-family: "GT-Walsheim-Regular";
}

body {
  display: flex;
  justify-content: center;
  background-color: $font-color;
  font-family: "GT-Walsheim-Regular";
  overflow: hidden;

  *:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    @media (max-width: $max-device-width) {
      display: none;
    }
  }

  .app-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $font-color;

    main {
      flex-grow: 1;
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow-y: scroll;

      .content-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        position: relative;

        @media (min-width: $max-device-width) {
          align-self: baseline;
        }
      }

      @media (max-width: $max-device-width) {
        top: 55px;
        bottom: 55px;
        position: fixed;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      @media (min-width: $max-device-width) {
        height: 100vh;
      }
    }

    .main-guest {
      @extend main;
      overflow-y: scroll;
    }

    svg {
      stroke-width: 1;
    }

    .footer-icon {
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .circle {
        flex-shrink: 0;
        background-color: #6dd400;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        margin-top: -5px;
        margin-left: 26px;
      }

      img {
        width: 40px;
      }

      p {
        margin-top: -4px;
      }
    }

    .footer-user-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      object-fit: cover;
    }

    .footer-user-icon.active {
      path {
        fill: $primary-background-color
      }
    }
  }
}
