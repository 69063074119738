@import '../../scss/colors';
@import '../../scss/screen-width.scss';

.loader-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  backdrop-filter: blur(3px);
  z-index: 92;
  max-width: $max-device-width;

  @media (max-width: $max-device-width) {
    margin-top: 55px;
  }

  .loader {
    position: absolute;
    top: 45%;
    left: 45%;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid $primary-background-color;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
}

.auth-loader-container {
  @extend .loader-container;
  max-width: none;
}

.tab-loader-container {
  height: 100%;
  width: 100%;
  max-width: $max-device-width;
  position: fixed;
  backdrop-filter: blur(3px);
  z-index: 10;

  .loader {
    position: absolute;
    top: 45%;
    left: 45%;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid $primary-background-color;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
}

.home-page-loader-container {
  @extend .tab-loader-container;

  @media (max-height: 600px) {
    .loader {
      top: 30%;
    }
  }
}

.user-page-loader-container {
  @extend .home-page-loader-container;

  @media (max-width: $max-device-width) {
    height: calc(100vh - 150px);
    position: absolute;
    
    .loader {
      top: 15%;
    }
  }

  @media (max-height: 600px) {
    .loader {
      top: 10%;
    }
  }

  &.big {
    @media (min-width: $max-device-width) {
      position: fixed;
      margin-top: -192px;
    }
  }
}

.invitation-page-loader-container {
  @extend .tab-loader-container;
  margin: 0;
  top: 0;
  height: 100%;
}

.topics-loader-container {
  @extend .tab-loader-container;
  left: auto;
  transform: none;

  .loader {
    left: 20%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
