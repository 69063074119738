@import '../../scss/colors';
@import '../../scss/mixins';

.invitations-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  height: 80px;
  width: 100%;
  border-bottom: 2px solid $light-grey;

  @media (min-width: $max-device-width) {
    border-radius: 10px;
    width: calc(100% - 20px);
    max-width: 578px;
    height: 95px;
    border: 1px solid $light-grey;
    box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
      0 1.5px 4px 0 rgba(0, 0, 0, 0.16);
    font-size: 14px;
    margin: 10px;
  }

  .invitation-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .invitation-text {
      margin: 0;
      @media (min-width: $max-device-width) {
        width: 80%;
      }
    }

    .invitation-time {
      margin: 0;
      color: $light-grey;
    }

    @media (min-width: $max-device-width) {
      flex-direction: column;
    }
  }

  .interlocutor-avatar {
    margin: 0 10px 0 16px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;

    @media (min-width: $max-device-width) {
      height: 46px;
      width: 46px;
    }
  }

  .see-details {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 19px;
    flex-shrink: 0;
    width: 70px;
    font-size: 11px;
    border-radius: 4px;
    margin-right: 16px;
    padding: 0;
    color: $primary-background-color;
    border: 1px solid $primary-background-color;

    @media (min-width: $max-device-width) {
      width: 119px;
      height: 50px;
      border-radius: 42px;
      border: 3px solid $primary-background-color;
      font-size: 16px;
      font-weight: bold;
    }
  }

  // .see-details:hover {
  //   color: $primary-background-color;
  //   background-color: rgba(250, 100, 0, 0.5);
  // }
}
