@import "../../scss/colors.scss";
@import "../../scss/mixins.scss";

.guest-page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  align-self: baseline;

  .guest-page-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;

    .app-title-container {
      width: 100%;
      flex-shrink: 3;

      .app-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: $primary-background-color;
        font-weight: bold;
        font-size: 24px;
        padding: 0 20px;
        margin: 0;
        height: 75px;
        border-bottom: 1px solid $line-color;
        position: sticky;
        top: 0;

        @media (max-width: 1150px) {
          font-size: 18px;
        }
      }
    }

    .home-page-container {
      @media (min-width: $max-device-width) {
        border: none;
      }

      @media (max-width: 1150px) {
        flex-shrink: 1.6;
      }

      .guest-page-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        max-width: 600px;
        height: 76px;
        border-bottom: 1px solid $line-color;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;

        .search-input-container {
          @include search-input-container;
          margin: 0;
          max-width: 600px;
          width: 100%;
          align-items: center;

          .search-input {
            width: 100%;
            height: 43px;
            margin: 16px 0;
          }

          .search-icon {
            top: 37%;
            left: 4%;
          }
        }
      }

      .coffee-cards-container {
        padding: 0;
        width: 100%;
        border-left: 1px solid $line-color;
        border-right: 1px solid $line-color;
        box-sizing: border-box;
        overflow-y: unset;
        flex: auto;
      }
    }

    .desktop-auth-container {
      width: 100%;
      flex-shrink: 2;
      margin-right: 50px;

      .desktop-auth {
        max-width: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $news-background;
        border-radius: 10px;
        margin: 16px auto 0 21px;
        flex-shrink: 1;
        position: sticky;
        top: 16px;
        max-height: calc(100vh - 32px);
        height: 100%;

        .text-container {
          margin: 20% 0 20% 0;
          width: 85%;

          @media (max-height: 710px) {
            margin: 7% 0;
          }

          @media (max-height: 800px) {
            margin: 8% 0;
          }

          .main-text {
            max-width: 310px;
            width: 100%;
            font-weight: bold;
            font-size: 26px;
            margin: 0;
            color: #000000;
            text-align: center;
            text-wrap: balance;

            @media (max-height: 710px) {
              font-size: 14px;
            }

            @media (max-height: 600px) {
              font-size: 12px;
            }
          }
        }

        .bottom-links {
          @include bottom-links();
          @media (max-width: 960px) {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .coffee-me-logo {
          max-width: 320px;
          width: 100%;
          margin-top: 30%;

          @media (max-height: 800px) {
            margin-top: 20%;
          }

          @media (max-height: 700px) {
            margin-top: 0;
          }
        }

        .social-icon {
          height: 26px;
          width: 26px;
        }

        .second-text {
          font-weight: normal;
          font-size: 14px;
          margin: 12px 0;

          @media (max-height: 650px) {
            margin: 0;
          }
        }

        .login-button {
          max-width: 310px;
          width: 95%;
          min-height: 45px;
          height: 45px;
          border-radius: 42px;
          font-weight: bold;
          color: $primary-background-color;
          border: 2px solid $primary-background-color;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.2s linear, border 0.2s linear, color 0.2s linear;

          &:hover {
            color: $primary-background-color-hover;
            background-color: $secondary-background-color;
            border: 2px solid $primary-background-color-hover;
          }

          @media (max-height: 530px) {
            min-height: 25px;
            height: 25px;
          }
        }

        // .login-button:hover {
        //   color: $primary-background-color;
        //   background-color: rgba(250, 100, 0, 0.5);
        // }

        .signup-button {
          @extend .login-button;
          margin: 3px 0;
          color: white;
          background-color: $primary-background-color;
          transition: background-color 0.2s linear, border 0.2s linear;

          &:hover {
            color: white;
            background-color: $primary-background-color-hover;
            border: 2px solid $primary-background-color-hover;
          }
        }

        // .signup-button:hover {
        //   color: $primary-background-color;
        //   background-color: rgba(250, 100, 0, 0.5);
        // }
      }
    }
  }
}
