@import "../../scss/screen-width.scss";
@import "../../scss/colors.scss";

.invitations-list-title {
  font-size: 18px;
  margin-top: 38px;
  span {
    padding: 0 16px;
  }

  p {
    padding: 0 16px;
    text-align: start;

    @media (min-width: $max-device-width) {
      text-align: center;
    }
  }

  .new-coffee-invitations-title {
    @media (min-width: $max-device-width) {
      display: none;
    }
  }
}

.invitations-page-container {
  position: relative;
  height: 100%;
  max-width: $max-device-width;
  width: 100%;

  @media (min-width: $max-device-width) {
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    min-height: 100vh;
    box-sizing: border-box;
  }

  .notifications-title {
    @media (max-width: $max-device-width) {
      display: none;
    }

    @media (min-width: $max-device-width) {
      position: sticky;
      top: 0;
      background-color: $font-color;
    }

    padding: 21px 0 24px 18px;
    font-weight: bold;
    font-size: 20px;
  }

  .tabs-container {
    padding-top: 10px;

    @media (min-width: $max-device-width) {
      p {
        color: $grey-text;
        border-bottom: 3px solid $light-grey;
      }

      .active-tab {
        color: $primary-background-color;
        border-bottom: 3px solid $primary-background-color;
      }
    }
  }
}
