@import "../../scss/screen-width.scss";
@import "../../scss/colors.scss";

.term-of-use {
  padding: 20px;
  overflow-y: auto;

  @media (min-width: $max-device-width) {
    padding: 20px;
    box-sizing: border-box;
    //max-width: $max-device-width;
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
  }

  ol {
    li {
      margin: 30px 0;
      p {
        margin: 10px 0;
      }

      ol {
        li {
          margin: 0;
        }
      }
    }
  }
}
