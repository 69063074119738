@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

a {
  text-decoration: none;
  margin: 0;
}

.desktop-nav-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  margin: 0 1% 0 2%;
  position: sticky;
  top: 0;

  .app-title {
    width: 190px;
    color: $primary-background-color;
    font-size: 24px;
    font-weight: bold;
    margin-top: 18px;
    cursor: pointer;
  }

  .nav-bar-container {
    overflow-y: auto;

    .nav-bar {
      height: 150px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;
    }

    .nav-bar-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      transition: background-color linear 0.2s;

      .icon-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 40px;
        color: $grey-text;

        .home-icon {
          width: 30px;
          height: 30px;
        }

        .notifications-icon {
          width: 30px;
          height: 30px;
        }

        .circle {
          flex-shrink: 0;
          background-color: #6dd400;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          margin-top: -14px;
          margin-left: 14px;
        }
      }

      .nav-bar-default-text {
        font-size: 18px;
        font-weight: bold;
        color: $black-color;
      }

      .nav-bar-selected-text {
        @extend .nav-bar-default-text;
        color: $primary-background-color;
      }

      &.active {
        border-radius: 39px;
        background-color: $background-nav-bar-color;
      }
    }

    .nav-bar-element:hover {
      border-radius: 39px;
      background-color: $secondary-background-color;
    }

    .sign-out {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0;

      .icon-container {
        width: 40px;
        display: flex;
        justify-content: flex-start;
      }

      .sign-out-text {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .desktop-nav-bar-user-icon {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      object-fit: cover;
    }

    .new-coffee-talk-button {
      @include new-coffee-talk-button();
    }

    .edit-availability {
      cursor: pointer;
      margin: 0;
      color: $primary-background-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      transition: background-color linear 0.2s;

      &:hover {
        background-color: $secondary-background-color;
      }
    }

    .top-line {
      width: 190px;
      margin: 40px 0 30px 0;
      border-top: solid 1px $line-color;
    }

    .lower-line {
      @extend .top-line;
      margin: 30px 0 40px 0;
    }
  }
}
