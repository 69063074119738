@import "../../scss/colors.scss";
@import "../../scss/screen-width.scss";
@import "../../scss/mixins.scss";

button {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
}

.time-page-tabs {
  font-size: 14px;
  display: flex;
  margin: auto;
  margin-bottom: 7%;
  flex-direction: row;
  width: 100%;

  @media (min-width: $max-device-width) {
    &.time-picker {
      width: 85%;
      margin: 0;
    }
  }

  .time-page-selected-tab-header {
    padding-bottom: 6px;
    margin: auto;
    text-align: center;
    color: $primary-background-color;
    border-bottom: 2px solid $primary-background-color;
    width: 50%;
    cursor: pointer;

    @media (min-width: $max-device-width) {
      border-bottom: 3px solid $primary-background-color;
      padding-bottom: 12px;
    }
  }

  .time-page-tab-header {
    padding-bottom: 6px;
    margin: auto;
    text-align: center;
    width: 50%;
    border-bottom: 1px solid $light-grey;
    cursor: pointer;

    @media (min-width: $max-device-width) {
      border-bottom: 3px solid $light-grey;
      color: $time-picker-desktop-color;
      padding-bottom: 12px;
    }
  }

  // .time-page-tab-header:hover {
  //   background-color: $light-grey;
  //   color: $font-color;
  // }
}

.time-page {
  top: 0;
  z-index: 100;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: $max-device-width;

  @media (max-width: $max-device-width) {
    backdrop-filter: blur(10px);
  }

  @media (min-width: $max-device-width) {
    background-color: rgba(0, 0, 0, 0.13);
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .time-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (min-width: $max-device-width) {
      justify-content: flex-start;
      width: 455px;
      height: initial;
      max-width: 90%;
      background-color: $modal-background-color;
      border-radius: 10px;
      box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
        0 1.5px 4px 0 rgba(0, 0, 0, 0.16);
    }

    @media screen and (max-height: 700px) {
      h2 {
        margin: 20% auto 0 auto;

        @media (min-width: $max-device-width) {
          margin: auto;
        }
      }

      .time-page-text {
        margin: 23px auto 27px auto;
      }
    }

    .time-page-text {
      margin: 50px 0;
      text-align: center;

      h3 {
        font-size: 20px;
        line-height: 30px;
        margin: 0;
      }
    }

    .close {
      position: absolute;
      z-index: 1;
      padding: 15px 15px 0 0;
      place-self: flex-end;
      cursor: pointer;

      @media (max-width: $max-device-width) {
        top: 15px;
        right: 15px;
        padding: 0;
      }
    }

    .availability-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      .availability-item {
        display: flex;
        flex-direction: column;

        .name {
          line-height: 21px;
        }

        @media (max-width: $max-device-width) {
          margin: 10px 0;
        }

        .availability-time-picker {
          position: absolute;
          margin-top: 63px;
          margin-left: 80px;
          z-index: 1;

          @media (max-width: $max-device-width) {
            margin-top: -100px;
          }
        }
      }

      @media (max-width: $max-device-width) {
        justify-content: center;
      }
    }

    .checkbox-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 30px;
      width: 270px;

      .MuiTypography-body1 {
        font-weight: normal !important;
      }
    }

    .enter {
      @include new-coffee-talk-button();
      margin: 30px 0;
    }

    .skip {
      margin-top: 3%;
      margin-bottom: 3%;
      font-size: 14px;
      cursor: pointer;

      @media (min-width: $max-device-width) {
        margin: 20px auto 30px auto;
      }
    }
  }
}

.MuiPaper-rounded {
  border-radius: 10px !important;
  color: $time-picker-desktop-color !important;
}

.MuiTypography-body1 {
  font-weight: bold !important;
}

.MuiPickersCalendarHeader-iconButton {
  color: $primary-background-color !important;
}

.MuiPickersDay-current {
  background-color: none !important;
  color: black !important;
}

.MuiPickersDay-daySelected {
  background-color: $primary-background-color !important;
  color: $font-color !important;
}

.MuiSvgIcon-root {
  color: $primary-background-color !important;
}

.MuiPickersCalendarHeader-iconButton {
  order: 1 !important;
}

.MuiTypography-caption {
  font-weight: 600 !important;
}

.MuiTypography-root {
  font-family: inherit !important;
}

.MuiPopover-paper {
  font-family: inherit !important;
}

.MuiTypography-colorInherit {
  font-weight: 600 !important;
}

.MuiPickersDay-day {
  font-family: inherit !important;
}

.MuiFormControl-marginNormal {
  border-bottom: 1px solid $line-color !important;
  border-radius: 10px !important;
}

.MuiInput-underline::before {
  display: none;
}

.MuiInput-underline::after {
  display: none;
}

.MuiInputBase-adornedEnd {
  color: $time-picker-desktop-color !important;
  font-family: inherit !important;
  width: 250px !important;
  height: 50px !important;
  border: 1px solid $light-grey !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  background-color: $font-color !important;
  padding-left: 20px;

  @media (max-width: $max-device-width) {
    width: 100% !important;
  }
}

.MuiFormControl-marginNormal {
  margin: 4px 0 0 0 !important;
}

.MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}

#error {
  border-radius: 10px;
  border: 1px solid transparent;
}

#anim-error {
  @extend #error;
  animation: blink 0.3s infinite;

  @media (min-width: $max-device-width) {
    width: 274px;
  }
}

@keyframes blink {
  50% {
    border-color: $red;
  }
}
