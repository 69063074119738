@import "../../../scss/colors.scss";
@import "../../../scss/mixins.scss";

.setting-input-container {
  width: 100%;

  input {
    width: 100%;
    height: 50px;
    background-color: $font-color;
    border: 1px solid $line-color;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 16px;
    padding: 0 20px;
    margin: 11px 0 20px 0;
    font-family: inherit;
  }

  .setting-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $line-color;
    padding-top: 20px;

    @media (max-width: $max-device-width) {
      justify-content: center;
    }

    .setting-cancel-button {
      width: 119px;
      height: 50px;
      background-color: $setting-cancel-button-color;
      border-radius: 42px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      font-family: inherit;
      cursor: pointer;
      transition: background-color 0.2s linear;

      &:hover{
        background-color: $grey-text;

      }

      @media (max-width: $max-device-width) {
        border-radius: 7px;
        height: 38px;
      }
    }

    .setting-save-button {
      @extend .setting-cancel-button;
      background-color: $font-color;
      border: 3px solid $primary-background-color;
      color: $primary-background-color;
      margin-left: 14px;
      transition: background-color 0.2s linear, border 0.2s linear, color 0.2s linear;

      &:hover{
        background-color: $secondary-background-color;
        border: 3px solid $primary-background-color-hover;
        color: $primary-background-color-hover;
      }

      @media (max-width: $max-device-width) {
        border-radius: 7px;
        height: 38px;
        border: 2px solid $primary-background-color;
      }
    }
  }
}
