@import "../../scss/colors.scss";
@import "../../scss/screen-width.scss";
@import "../../scss/mixins.scss";

.user-avatar-edit-container {
  z-index: 20;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.13);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .user-avatar-edit-pop-up {
    background: #ffffff;
    border-radius: 10px;
    width: 455px;
    height: 640px;
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 1.5px 4px 0 $primary-shadow-color,
      0 -0.5px 6px 0 $primary-shadow-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &.empty {
      height: 400px;

      @media (max-width: $max-device-width) {
        height: 310px;
      }
    }

    &.one {
      height: 450px;
    }

    &.two {
      height: 560px;
    }

    &.small {
      height: 342px;
    }

    @media (max-width: $max-device-width) {
      height: 493px;
      max-width: 90%;
      max-height: 75%;
    }

    .close {
      position: absolute;
      z-index: 1;
      padding: 15px 15px 0 0;
      place-self: flex-end;
      cursor: pointer;
    }

    .delete {
      @extend .close;
      margin: 275px 0 0 0;
    }

    .user-avatar-edit {
      position: relative;
      width: 100%;
      max-width: $max-device-width;
      max-height: calc(100% - 295px);
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      flex: 0 0 100%;

      @media (max-width: $max-device-width) {
        max-height: 50%;
      }

      &.empty {
        max-height: calc(100% - 110px);
      }

      &.one {
        max-height: calc(100% - 170px);
      }

      &.two {
        max-height: calc(100% - 235px);
      }

      &.small {
        height: 100%;
        max-height: 100%;

        img {
          border-radius: 10px;
        }
      }

      img {
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        height: 342px;
        margin: auto;
      }

      .slider {
        height: 100% !important;
      }

      .left-arrow {
        width: 0;
        height: 0;
      }

      .right-arrow {
        width: 0;
        height: 0;
      }
    }

    .user-avatar-edit:hover {
      .left-arrow {
        height: auto;
        width: auto;
        cursor: pointer;
        margin-left: -45px;

        img {
          height: 40px;
          width: 40px;
        }
      }

      .right-arrow {
        @extend .left-arrow;
        margin-left: 0;
        margin-right: -45px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .user-details {
      height: 100%;
      max-height: 50%;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      flex-direction: column;

      .user-name-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 110px;
        flex-direction: column;
        width: 100%;

        .follow {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 25px;
          font-weight: bold;

          .name {
            margin-left: 20px;
            margin-bottom: 10px;
          }

          .follow-button {
            @include follow-button;
          }

          .unfollow-button {
            @include unfollow-button;
          }
        }

        .verification {
          font-size: 25px;
          font-weight: bold;
          margin-left: 20px;
          margin-bottom: 0;
        }
      }

      .detail-container {
        .detail {
          font-size: 14px;
          font-weight: bold;
          margin-left: 20px;
          margin-bottom: 5px;

          &.value {
            font-weight: normal;
            margin-bottom: 10px;
          }
        }
      }
    }

    .upload-button-container {
      position: absolute;
      z-index: 1;
      place-self: flex-end;
      margin: 15px 285px 0 0;

      @media (max-width: $max-device-width) {
        left: 13%;
      }

      @media (max-width: 500px) {
        left: 10%;
      }

      .upload-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-background-color;
        color: $font-color;
        width: 153px;
        height: 40px;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;

        .photo-order {
          margin-left: 9px;
          color: $photo-order-color;
        }
      }
    }

    // .upload-avatar:hover {
    //   color: $primary-background-color;
    //   background-color: rgba(250, 100, 0, 0.5);
    // }

    .cancel-edit {
      margin-bottom: 20px;
      font-weight: 500;
      position: relative;
      color: $black-color;
      border: none;
      top: 0;
      right: 0;
      cursor: pointer;

      &.user {
        margin: auto;
      }
    }
  }
}

.empty-avatar {
  display: flex;
  margin: auto;
  width: 300px;
  height: 300px;
}
