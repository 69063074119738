@import "../../../scss/colors.scss";

.desktop-context-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  max-width: 343px;
  z-index: 1;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.14);
  border-radius: 9px;
  background-color: $font-color;
  right: 25px;
  padding: 10px 0;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .menu-button {
    display: flex;
    color: $primary-background-color;
    width: 100%;
    padding: 0 15px;
    justify-content: flex-start;
    align-items: center;
    color: $black-color;
    font-size: 15px;
    cursor: pointer;
    height: 20px;
    position: initial !important;
    width: inherit !important;
    color: initial !important;

    p {
      margin: 0 0 0 10px;
    }

    svg {
      stroke-width: 0;
    }
  }
}
