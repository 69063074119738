@import "../../scss/colors.scss";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

p {
  margin: auto;
}

.user-page {
  font-size: 14px;
  width: 100%;
  max-width: $max-device-width;
  display: flex;
  flex-direction: column;

  @media (min-width: $max-device-width) {
    border-left: 1px solid $line-color;
    border-right: 1px solid $line-color;
    box-sizing: border-box;
    min-height: 100vh;
  }

  .profile-title {
    font-size: 20px;
    font-weight: bold;
    padding: 21px 19px 0 19px;
    display: flex;
    justify-content: space-between;

    @media (min-width: $max-device-width) {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $font-color;
    }

    svg {
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: $secondary-background-color;
      }
    }

    .delete-button {
      color: $primary-background-color;
      cursor: pointer;
    }
  }

  .user-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    @media (min-width: $max-device-width) {
      position: sticky;
      top: 51px;
      z-index: 1;
      flex-shrink: 0;
      background-color: $font-color;
    }

    .user-avatar {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 60px;
      height: 66px;
      border-radius: 50%;
      width: 100%;

      .user-img {
        object-fit: cover;
        height: 62px;
        width: 62px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .user-name {
      margin-top: 5px;
      font-size: 22px;
    }

    .user-location {
      margin-bottom: 15px;
      color: $light-grey;
    }

    .verifications-text {
      margin: 10px 0;
    }

    button {
      @include button();
      position: absolute;
      width: 100px;
      height: 30px;
      color: $primary-background-color;
    }

    .followers-list {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }

    .followers-button {
      position: inherit;
      font-weight: bold;
      font-size: 18px;
      height: 49px;
      cursor: pointer;
      font-family: inherit;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: $secondary-background-color;
      }

      .text {
        color: #888888;
        font-weight: 500;
        font-size: 14px;
      }
    }

    @media (min-width: $max-device-width) {
      display: flex;
      justify-content: flex-start;
      align-self: auto;
      flex-direction: row;
      height: 149px;

      .user-avatar {
        margin: 0 0 0 19px;
        height: 46px;
        width: 46px;

        .user-img {
          height: 46px;
          width: 46px;
        }
      }

      .user-name {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }

      .user-data {
        height: 46px;
        margin-left: -10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: $max-device-width) {
          margin-left: 24px;
          border-right: 1px solid $line-color;
          padding-right: 28px;
        }
      }
    }

    .user-verifications-container {
      @extend .user-data-container;
      margin-bottom: 29px;
    }

    .dots {
      height: 20px;
      width: 20px;
      position: absolute;
      top: 5px;
      right: 5px;
      stroke-width: 0;
    }

    .context-menu-container {
      z-index: 20;
      height: calc(100vh - 55px);
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.13);
      position: fixed;
      bottom: 55px;
      display: flex;
      align-items: flex-end;

      .context-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $font-color;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);

        .customResizeBorder {
          height: 20px;
        }

        .customHandle {
          background-color: $edit-setting-button-color;
          margin: 5px auto;
          width: 50px;
          height: 5px;
          border-radius: 5px;
        }
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $primary-background-color;
        height: 40px;
        width: 200px;
        position: relative;
        border: none;
        border-radius: 0;
        background-color: $font-color;

        svg {
          height: 24px;
          width: 24px;
          stroke-width: 0;
        }
      }

      .menu-button {
        color: $primary-background-color;
        width: 100%;
        padding: 0 15px;
        justify-content: flex-start;
        color: $black-color;
        font-size: 15px;

        p {
          margin: 0 0 0 10px;
        }
      }
    }
  }

  @media (min-width: $max-device-width) {
    .tabs-container {
      position: sticky;
      top: 200px;
    }
  }

  .user-page-tabs {
    font-size: 14px;
    display: flex;
    flex-direction: row;

    .user-page-selected-tab-header {
      padding-bottom: 6px;
      margin: auto;
      text-align: center;
      color: $primary-background-color;
      border-bottom: 2px solid $primary-background-color;
      width: 50vw;
    }

    .user-page-tab-header {
      padding-bottom: 6px;
      margin: auto;
      text-align: center;
      width: 50vw;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.coffee-cards-container-wrapper {
  overflow: scroll;

  @media (max-width: $max-device-width) {
    height: calc(100vh - 150px);
  }

  .coffee-cards-container {
    @include coffee-cards-container;
    @media (max-width: $max-device-width) {
      overflow-y: scroll;
    }
  }

  .coffee-cards-container::-webkit-scrollbar {
    width: 0;
  }
}

.coffee-cards-container-wrapper::-webkit-scrollbar {
  width: 0;
}

.load-avatar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $font-color;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(247, 100, 32, 0.7);
  opacity: 0.9;
  height: 62px;
  width: 62px;

  position: absolute;
  top: 60px;
  left: calc(50% - 31.2px);

  @media (min-width: $max-device-width) {
    left: 19px;
    top: 48.5px;
    height: 46px;
    width: 46px;
  }
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
}

.dots {
  cursor: pointer;
  stroke-width: 0 !important;
}

.stripe-connect {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    max-width: initial;
    width: auto;
    padding: 0 16px;
    margin: 0;
    border-radius: 42px;
    height: 50px;

    @media (max-width: $max-device-width) {
      border-radius: 7px;
      height: 38px;
      width: 100%;
    }
  }
}

.stripe-connect-wrapper {
  margin: 0 19px 11px 19px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid $primary-background-color;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
