@import '../../scss/colors.scss';
@import '../../scss/screen-width.scss';

.interlocutor-info {
  p {
    margin: 0 0 0 10px;
  }
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  flex-shrink: 0;

  .user-text-data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $max-device-width) {
      margin-left: 10px;
    }

    .user-name {
      margin: 0;
      font-size: 20px;

      @media (max-width: $max-device-width) {
        margin-left: 10px;
      }
    }

    .user-city {
      color: $light-grey;
      font-size: 14px;
    }
  }

  .user-img {
    height: 62px;
    width: 62px;
    border-radius: 50%;
    object-fit: cover;
  }
}
