@import "../../scss/screen-width.scss";
@import "../../scss/colors.scss";

.stripe-modal-background {
  z-index: 20;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.13);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .stripe-modal {
    width: 495px;

    height: auto;
    background-color: $font-color;
    border-radius: 10px;
    padding: 20px;

    .stripe-modal-header {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;

        &:hover {
          color: $primary-background-color;
        }
      }
    }

    .stripe-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 300px;

      .continue-to-payment{
        font-size: 16px;
        font-weight: 700;
        border: 3px solid $primary-background-color;
        cursor: pointer;
        padding: 10px 36px;
        background-color: $primary-background-color;
        color: $font-color;
        transition: background-color 0.2s linear, border 0.2s linear;
        width: 240px;
        max-width: unset;

        &:hover{
          border: 3px solid $primary-background-color-hover;
          background-color: $primary-background-color-hover;
        }

        &:hover{
          background-color: $primary-background-color-hover;
        }
      }

      .stripe-modal-body-input-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .pre-input{
          position: absolute;
          left: 8px;
          top: 24px;
          color: $grey-text;
          border-right: 1px solid lightgray;
          padding-right: 6px;
        }

        .post-input{
          position: absolute;
          border-left: 1px solid lightgray;
          padding-left: 6px;
          right: 8px;
          top: 24px;
          color: $grey-text;
        }

        input{
          padding: 4px 48px 0 30px ;
        }

        .stripe-modal-body-input-error-message {
          color: $red;
        }
      }
    }

    .stripe-payment-form {
      border: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      min-height: 300px;


      & > div {
        width: 100%;
      }

      .stripe-payment-form-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap-reverse;

        @media (max-width: $max-device-width) {
          justify-content: center;
        }

        .button-secondary {
          color: $primary-background-color;
          border: 3px solid $primary-background-color;
          background-color: transparent;
          transition: color 0.2s linear, border 0.2s linear;

          &:hover {
            color: $primary-background-color-hover;
            border: 3px solid $primary-background-color-hover;
          }
        }
      }
    }

    button {
      margin: 30px 0 0;
      cursor: pointer;
      color: $setting-cancel-button-color;
      background-color: $primary-background-color;
      border: 3px $primary-background-color solid;
      height: 50px;
      max-width: 200px;
      font-size: 14px;
      border-radius: 25px;
      transition: background-color 0.2s linear, border 0.2s linear;

      &:hover {
        color: $setting-cancel-button-color;
        background-color: $primary-background-color-hover;
        border: 3px solid $primary-background-color-hover;
      }
    }

    button:disabled {
      color: $setting-cancel-button-color;
      border: 3px solid $setting-cancel-button-color;
    }

    @media (max-width: $max-device-width) {
      width: 100%;
      height: 100%;
      max-width: 80%;
      max-height: 70%;
    }
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    width: 100%;
  }
}