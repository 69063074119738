@import '../../scss/mixins.scss';

.lite-coffee-card {
  @include opened-coffee-card;
  width: 100%;
  margin: 10px 0;

  @media (min-width: $max-device-width) {
    min-height: 175px;
  }

  .coffee-card-data {
    margin-bottom: 5px;

    .coffee-card-tag {
      @media (min-width: $max-device-width) {
        font-size: 14px;
        margin-bottom: 30px;
        margin-top: -15px;
      }
    }

    .coffee-card-title {
      margin-bottom: 5px;

      @media (min-width: $max-device-width) {
        margin-bottom: 20px;
      }
    }

    @media (min-width: $max-device-width) {
      width: 80%;
    }
  }
}

.lite-coffee-card:hover {
  background-color: $secondary-background-color;
}
