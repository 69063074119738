@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.coffee-to-select {
  @include coffee-list();

  @media (min-width: $max-device-width) {
    margin: 15px 10px 0 10px;
  }

  .button-container {
    flex-shrink: 0;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    @include button();

    @media (min-width: $max-device-width) {
      align-items: center;
      .active {
        color: $primary-background-color;
      }
    }

    .coffee-select-button {
      padding: 0;
      width: 75px;
      height: 35px;
      font-size: 13px;

      @media (min-width: $max-device-width) {
        width: 119px;
        height: 50px;
      }
    }

    // .coffee-select-button:hover {
    //   color: $primary-background-color;
    //   background-color: rgba(250, 100, 0, 0.5);
    //   border: 3px solid $primary-background-color;
    // }

    .active {
      text-align: center;
      color: $primary-background-color;
      border: 2px solid $primary-background-color;

      @media (min-width: $max-device-width) {
        width: 119px;
        border: 3px solid $primary-background-color;
      }
    }
  }
}
