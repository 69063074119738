// only an example
$primary-background-color: #fa6400;
$primary-background-color-hover: #DD4803;
$secondary-background-color: #efeeee;
$black-color: #000000;
$font-color: #ffffff;
$stroke: #6dd400;
$light-grey: #d5d5d5;
$primary-shadow-color: rgba(0, 0, 0, 0.16);
$grey-text: #979797;
$toggle: #4cd964;
$tag-background-color: #f2f2f2;
$line-color: #e9e9e9;
$border-color: #ebebeb;
$slider-color: #ffd8be;
$news-background: #f5f8fa;
$middle-news: #ebeced;
$time-picker-desktop-color: #878787;
$facebook-color: #4267b2;
$background-nav-bar-color: #fffbfa;
$input-color: #7e7e7e;
$modal-background-color: #f5f8fa;
$edit-setting-button-color: #e4e6eb;
$setting-cancel-button-color: #eeeeee;
$photo-order-color: #ffd4b7;
$zoom-color: #5a8af7;
$red: #ff0000;
$blue: #1389f7;
$stripe: #615bff;
