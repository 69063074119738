@import '../../scss/colors.scss';
@import '../../scss/screen-width.scss';

.verifications-container {
  margin-left: 0;
  width: 260px;

  @media (max-width: $max-device-width) {
    width: 195px;
  }

  .verifications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-size: 10px;
    width: 195px;

    @media (min-width: $max-device-width) {
      width: 260px;
      font-size: 14px;
      color: $grey-text;
    }

    .not-verification-icon {
      margin: 0 2px;
    }

    .verification-icon {
      @extend .not-verification-icon;
      color: $stroke;
    }
  }
}
