@import "../../scss/colors";
@import "../../scss/mixins";

.detail-text {
  font-size: 14px;
  color: $light-grey;
  margin: 10px 0 10px 0;
  @media (min-width: $max-device-width) {
    // color: $black-color;
  }
}

.coffee-details-container {
  font-size: 16px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;

  .coffee-details {
    font-size: 18px;
    margin-bottom: 15px;
    @media (min-width: $max-device-width) {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .respective {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    textarea {
      font-family: inherit;
      margin-top: 10px;
      @include input();
      font-size: 16px;
      height: 88px;
    }

    .respective-text {
      margin-top: 10px;
      word-wrap: break-word;
    }

    .characters-left-container {
      display: flex;
      justify-content: flex-end;

      p {
        font-size: 14px;
        margin: 0;
        color: $grey-text;
      }
    }
  }

  .coffee-meeting-details {
    @include meeting-details;
  }

  .coffee-meeting-details-titles {
    @include meeting-details-titles;
  }

  .coffee-meeting-details-data {
    @include meeting-details-data;
  }
}

.meeting-confirmation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  width: 100%;
  margin: 40px auto 75px auto;
  @include button();

  .active {
    color: $primary-background-color;
  }

  // .active:hover {
  //   color: $primary-background-color;
  //   background-color: rgba(250, 100, 0, 0.5);
  // }

  button {
    margin: 7.5px 0;

    @media (min-width: $max-device-width) {
      width: 100%;
      max-width: 205px;
    }
  }

  // button:hover {
  //   background-color: rgba(213, 213, 213, 0.5);
  // }

  @media (min-width: $max-device-width) {
    justify-content: space-between;
    flex-direction: row;

    .back-to-feed-button {
      flex-shrink: 0;
      width: 119px;
    }

    .cancel-talk-button:hover, .back-to-feed-button:hover{
      background-color: $grey-text;
    }

    .active {
      color: $primary-background-color;

      &:hover {
        color: $primary-background-color-hover;
        border-color: $primary-background-color-hover;
      }
    }
  }
}
