@import '../../../scss/colors.scss';

.setting-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  max-width: 343px;
  height: 78px;
  z-index: 1;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.14);
  border-radius: 9px;
  background-color: $font-color;

  .setting-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;

    p {
      margin: 0;
    }

    img {
      margin: 0 10px 0 13px;
    }
  }
}
