@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.13);

  @media (max-width: $max-device-width) {
    top: 55px;
  }

  form {
    border: none;
    box-sizing: initial;
    width: inherit;
  }

  .user-img-close-button {
    cursor: pointer;
    position: relative;
    height: 54px;
    width: 54px;
  }

  #firebaseui_container {
    width: 90%;
  }

  .firebaseui-form-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

.auth-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 676px;
  // height: 545px;
  max-width: 95%;
  max-height: 95%;
  //min-height: 450px;
  background-color: $modal-background-color;
  border-radius: 10px;
  box-shadow: 0 -0.5px 6px 0 rgba(0, 0, 0, 0.16),
    0 1.5px 4px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: $max-device-width) {
    height: 100%;
    width: 100%;
    border-radius: 0;
    max-width: 100%;
    justify-content: flex-start;
    padding-top: 50px;
    margin: 0;
  }

  .auth-state {
    margin-bottom: 28px;

    .sign-button {
      cursor: pointer;
      text-decoration: underline;
      font-size: 16px;
      padding: 0;
    }
  }

  .close-auth-modal {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    max-width: 310px;
    margin-top: 38px;

    @media (max-width: $max-device-width) {
      display: none;
    }
  }

  .auth-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: auto;

    @media (max-width: $max-device-width) {
      margin: 0;
    }

    .app-title {
      color: $primary-background-color;
      font-weight: bold;
      font-size: 24px;
      margin-top: 3%;
    }

    .modal-text {
      display: flex;
      width: 100%;
      max-width: 310px;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      margin: 0;
      color: $primary-background-color;
    }

    .gmail-button {
      width: 100%;
      max-width: 310px;
      height: 50px;
      border-radius: 5px;
      background-color: $font-color;
      color: $grey-text;
      font-weight: bold;
      margin: 3% 0 20px 0;
      font-size: 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 1.5px 4px 0 $primary-shadow-color,
        0 -0.5px 6px 0 $primary-shadow-color;
    }

    .facebook-button {
      @extend .gmail-button;
      margin: 0 0 20px 0;
      color: $font-color;
      background-color: $facebook-color;
    }

    .apple-button {
      @extend .gmail-button;
      margin: 0 0 42px 0;
    }
  }

  .bottom-links {
    @include bottom-links();
    flex: inherit;
    margin-bottom: 25px;
  }

  .modal-window-header {
    height: 55px;
    width: 100%;
    background-color: $primary-background-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    flex-shrink: 0;

    @media (max-width: $max-device-width) {
      display: none;
    }

    .back-button-with-icon {
      @include back-button-with-icon;
    }

    h1 {
      margin: 0;
      font-size: 27px;
      font-weight: lighter;
      margin: 0 15px;
    }
  }
}
