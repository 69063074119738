@import "../../scss/colors";
@import "../../scss/mixins";
@import "../../scss/screen-width.scss";

input {
  width: 100%;
  height: 50px;
  background-color: $font-color;
  border: 1px solid $line-color;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  padding: 0 20px;
  margin: 11px 0 20px 0;
  font-family: inherit;
}

.stripe-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 495px;
  height: 350px;
  background-color: $font-color;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: $max-device-width) {
    width: 100%;
    max-width: 80%;
    height: auto;
  }

  button {
    cursor: pointer;
  }

  h2 {
    text-align: center;
  }

  .save-button {
    width: 200px;
    height: 50px;
    border-radius: 42px;
    border: 3px solid $primary-background-color;
    font-weight: 700;
    font-size: 14px;
    padding: 0;
    background-color: $primary-background-color;
    color: $font-color;

    @media (max-width: $max-device-width) {
      border-radius: 7px;
      height: 38px;
      width: 100%;
    }
  }

  .save-button[disabled] {
    border: 3px solid $light-grey;
    color: $light-grey;
    background-color: $font-color;

    @media (max-width: $max-device-width) {
      border: 1px solid $light-grey;
    }
  }
}
