@import '../../scss/screen-width.scss';
@import '../../scss/colors.scss';

.google-map-container {
  // important needed to override styles built into the map
  height: 190px !important;
  width: 100%;
  max-width: $max-device-width;
  position: relative !important;

  @media (min-width: $max-device-width) {
    margin: 0 10px;
    height: 400px !important;
    max-width: calc(100% - 20px);
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid $light-grey;
  }
}

.search-box-container {
  position: sticky;
  top: 85px;
  background-color: $font-color;
  z-index: 1;
}
