@import '../../scss/colors';
@import '../../scss/mixins';

.tabs-container {
  z-index: 1;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  cursor: pointer;

  .tab {
    &:hover {
      background-color: $secondary-background-color;
    }
  }

  @media (min-width: $max-device-width) {
    position: sticky;
    top: 75px;
    background-color: $font-color;
    z-index: 1;
  }

  p {
    padding-bottom: 6px;
    margin: auto;
    text-align: center;
    border-bottom: 1px solid $light-grey;
    width: 100%;
  }

  .active-tab {
    color: $primary-background-color;
    border-bottom: 2px solid $primary-background-color;
  }

  @media (min-width: $max-device-width) {
    p {
      color: $grey-text;
      border-bottom: 3px solid $light-grey;
    }

    .active-tab {
      color: $primary-background-color;
      border-bottom: 3px solid $primary-background-color;
    }
  }
}
