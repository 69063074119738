@import '../../scss/colors.scss';
@import '../../scss/mixins.scss';

.topics-container {
  display: flex;
  flex-direction: column;
  max-height: 97vh;
  width: 350px;
  border-radius: 10px;
  background-color: $news-background;
  margin: 16px 1% 0 2%;
  align-self: flex-start;
  position: sticky;
  top: 16px;

  @media (max-width: 900px) {
    display: none;
  }

  .topics-for-you {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 0 20px;
  }

  .in-the-news-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 20px;

    .in-the-news {
      color: $primary-background-color;
      flex-shrink: 0;
      margin-right: 15px;
    }

    hr {
      border: none;
      width: 100%;
      background-color: $primary-background-color;
      height: 1px;
    }
  }

  .news-list-container {
    height: 100%;
    overflow-y: scroll;

    .empty-news {
      width: 100%;
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }

  .news-list-container::-webkit-scrollbar {
    width: 0;
  }
}
