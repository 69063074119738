@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/screen-width.scss';

.payment-type-button {
  white-space: nowrap;
  border: 3px solid $primary-background-color;
  border-radius: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: $primary-background-color;
  font-size: 16px;
  width: 200px;

  .MuiFormControlLabel-root {
    margin: 0;
  }

  svg {
    height: 20px;
    width: 20px;
    stroke-width: 0 !important;
  }

  &.active {
    border-color: $primary-background-color;
    color: $font-color;
    background-color: $primary-background-color;
  }

  > div {
    padding-left: 5px;
  }

  @media (max-width: $max-device-width) {
    border-width: 2px;
    border-radius: 7px;
    width: 130px;
    font-size: 14px;
  }
}

.tooltip-badge {
  padding-left: 5px;
}
