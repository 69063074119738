@import "../../scss/colors";
@import "../../scss/screen-width.scss";

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  min-height: 55px;
  background-color: $font-color;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  a {
    text-decoration: none;
    width: 25%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 30px;
      color: $primary-background-color;
    }

    p {
      margin: 0;
      height: 12.8px;
      width: 75px;
      color: $black-color;
      font-size: 10px;
      letter-spacing: 0.12px;
      line-height: 13px;
      text-align: center;
    }
  }
}

.basic-footer {
  position: fixed;
  bottom: 0;
  z-index: 91;
  width: 100%;
  max-width: $max-device-width;
}

.hidden-footer {
  visibility: hidden;
}
