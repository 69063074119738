@import "../../../scss/colors";
@import "../../../scss/mixins";
@import "../../../scss/screen-width.scss";

.get-paid-input {
  padding: 5px 0 32px 0 !important;

  input {
    width: 100%;
    height: 50px;
    border: 1px solid $line-color;
    box-sizing: border-box;
    border-radius: 7px;
    font-size: 16px !important;
    padding: 0 20px;
    margin: 0;
    font-family: inherit;
    background-color: $news-background !important;
    color: $time-picker-desktop-color !important;
    box-shadow: unset !important;
  }
}
